
import { Component, Prop, Vue } from "vue-property-decorator";
import TitleVerticalBar from "./TitleVerticalBar.vue";

@Component({
  components: {
    TitleVerticalBar
  }
})
export default class CardBasic extends Vue {
  @Prop({ required: false })
  title?: string;

  get pageTitle() {
    return this.title ? this.title : this.$t(this.$route.meta.title);
  }
}
