export enum LocaleCodes {
  GeneralUnknownError = "GENERAL_UNKNOWN_ERROR",
  GeneralNetworkError = "GENERAL_NETWORK_ERROR",
  GeneralAuthTokenExpiredError = "GENERAL_AUTH_TOKEN_EXPIRED_ERROR",

  NavigationSectionCustomers = "COMMON_INTERFACE_CUSTOMERS_TEXT",
  NavigationSectionData = "COMMON_INTERFACE_DATA_TEXT",

  NavigationItemEndpoints = "COMMON_INTERFACE_ENDPOINTS_TEXT",
  NavigationItemEvents = "COMMON_INTERFACE_EVENTS_TEXT",
  NavigationItemErrors = "COMMON_INTERFACE_ERRORS_TEXT",

  PageTitleEndpoints = "COMMON_INTERFACE_ENDPOINTS_TEXT",
  PageTitleEvents = "COMMON_INTERFACE_EVENTS_TEXT",
  PageTitleEndpointEvents = "PAGE_TITLE_ENDPOINT_EVENTS_TEXT",
  PageTitleErrors = "COMMON_INTERFACE_ERRORS_TEXT",
  PageTitleErrorsSearch = "PAGE_TITLE_ERRORS_SEARCH_TEXT"
}
