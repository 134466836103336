import { LocaleCodes } from "@/interfaces/locale/codes";
import {
  Navigation,
  NavigationItem,
  NavigationSection
} from "@/interfaces/store/router";
import { Route } from "./routes";

export const navigation = new Navigation()
  .addSection(
    new NavigationSection(LocaleCodes.NavigationSectionCustomers).addItem(
      new NavigationItem(
        Route.Endpoints,
        LocaleCodes.NavigationItemEndpoints,
        "/",
        "mdi-connection"
      )
    )
  )
  .addSection(
    new NavigationSection(LocaleCodes.NavigationSectionData)
      .addItem(
        new NavigationItem(
          Route.Events,
          LocaleCodes.NavigationItemEvents,
          "/events",
          "mdi-calendar-search"
        )
      )
      .addItem(
        new NavigationItem(
          Route.Errors,
          LocaleCodes.NavigationItemErrors,
          "/errors",
          "mdi-message-flash"
        )
      )
  );
