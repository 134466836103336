
import { Route } from "@/router/routes";
import { Actions } from "@/store/operations";
import { Component, Vue } from "vue-property-decorator";
import { isEnvironmentTest } from "@/common/utils/env";
import { userService } from "@/services/user";

@Component
export default class AppBar extends Vue {
  get appName() {
    return process.env.VUE_APP_NAME + (this.isTestEnv ? " TEST" : "");
  }

  get userName() {
    return userService.personalName;
  }

  get userEmail() {
    return userService.personalEmail;
  }

  get isTestEnv() {
    return isEnvironmentTest();
  }

  logout() {
    this.$store
      .dispatch(Actions.UserLogout)
      .then(() => this.$router.push({ name: Route.Login }));
  }

  async toggleDrawer() {
    await this.$store.dispatch(Actions.DrawerToggle);
  }
}
