import { DrawerSettings } from "@/interfaces/services/interface";
import { localStorageService } from "./local-storage";
import { UserDataService } from "./user-data";

class InterfaceService extends UserDataService {
  protected readonly dataKeys = ["interfaceSettings"];

  getDrawerOpened() {
    return this.drawer.opened;
  }

  setDrawerOpened(opened: boolean) {
    this.setDrawerLocalSettings({ opened });
  }

  getDrawerMini() {
    return this.drawer.mini;
  }

  setDrawerMini(mini: boolean) {
    this.setDrawerLocalSettings({ mini });
  }

  getLanguage() {
    return (
      this.interfaceSettings.language || process.env.VUE_APP_LOCALE_DEFAULT
    );
  }

  setLanguage(language: string) {
    this.setLanguageInterfaceSettings(language);
  }

  protected get drawer(): DrawerSettings {
    const localSettings = this.localSettings;
    return localSettings.drawer === undefined
      ? { opened: true, mini: false }
      : localSettings.drawer;
  }

  protected get localSettings(): any {
    const localSettingsStr = localStorageService.get(
      localStorageService.keyUserDataLocalSettings
    );
    return localSettingsStr == null ? {} : JSON.parse(localSettingsStr);
  }

  protected get interfaceSettings(): any {
    const interfaceSettingsStr = localStorageService.get(
      localStorageService.keyUserDataInterfaceSettings
    );
    return interfaceSettingsStr == null ? {} : JSON.parse(interfaceSettingsStr);
  }

  protected setDrawerLocalSettings(settings: Partial<DrawerSettings>) {
    const localSettings = this.localSettings;
    localSettings.drawer = {
      ...localSettings.drawer,
      ...settings
    };
    localStorageService.set(
      localStorageService.keyUserDataLocalSettings,
      JSON.stringify(localSettings)
    );
  }

  protected setLanguageInterfaceSettings(language: string) {
    const interfaceSettings = this.interfaceSettings;
    interfaceSettings.language = language;
    localStorageService.set(
      localStorageService.keyUserDataInterfaceSettings,
      JSON.stringify(interfaceSettings)
    );
  }
}

export const interfaceService = new InterfaceService();
