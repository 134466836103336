export enum Actions {
  UserLogin = "user_login",
  UserLogout = "user_logout",

  BookmarkEventSetData = "bookmark_event_set_data",
  BookmarkEventLeave = "bookmark_event_leave",
  BookmarkEventItemGet = "bookmark_event_get",
  BookmarkEventItemStore = "bookmark_event_store",
  BookmarkEventItemRemove = "bookmark_event_remove",
  BookmarkEventItemReset = "bookmark_event_reset",
  BookmarkEventList = "bookmark_event_list",
  BookmarkEventSetStatusLive = "bookmark_event_set_status_live",
  BookmarkEventSetStatusFinished = "bookmark_event_set_status_finished",
  BookmarkGlobalStateSet = "bookmark_global_state_set",
  BookmarkStartTimeSet = "bookmark_start_time_set",
  BookmarkStopTimeSet = "bookmark_stop_time_set",
  BookmarkDetailItemsSet = "bookmark_detail_items_set",

  RouteChange = "route_change",

  DrawerToggle = "drawer_toggle",
  DrawerMiniToggle = "drawer_mini_toggle",
  LanguageChange = "language_change"
}

export enum Mutations {
  UserAuthSet = "user_auth_set",
  UserAuthPurge = "user_auth_purge",
  UserAuthSetError = "user_auth_error",
  UserAuthPurgeErrors = "user_auth_purge_errors",
  UserLoginLoadingToggle = "user_login_loading_toggle",

  BookmarkEventSet = "bookmark_event_set",
  BookmarkEventPurge = "bookmark_event_purge",
  BookmarkEventItemLoadingToggle = "bookmark_event_item_loading_toggle",
  BookmarkEventItemSetError = "bookmark_event_item_set_error",
  BookmarkEventItemPurgeErrors = "bookmark_event_item_purge_errors",
  BookmarkEventListSet = "bookmark_event_list_set",
  BookmarkEventListLoadingToggle = "bookmark_event_list_loading_toggle",
  BookmarkEventListSetError = "bookmark_event_list_set_error",
  BookmarkEventListPurgeErrors = "bookmark_event_list_purge_errors",
  BookmarkEventStatusChange = "bookmark_event_status_change",
  BookmarkIdSet = "bookmark_id_set",
  BookmarkGlobalStateSet = "bookmark_global_state_set",
  BookmarkGlobalStateTempSet = "bookmark_global_state_temp_set",
  BookmarkStartTimeSet = "bookmark_start_time_set",
  BookmarkStopTimeSet = "bookmark_stop_time_set",
  BookmarkDetailItemsSet = "bookmark_details_items_set",

  RouteActiveSet = "route_active_set",

  DrawerOpenedToggle = "drawer_opened_toggle",
  DrawerMiniToggle = "drawer_mini_toggle",
  LanguageChange = "language_change"
}

export enum Getters {
  UserLoginHasErrors = "user_login_has_errors",

  BookmarkEventHasGlobalStateFields = "bookmark_event_has_global_state_fields",
  BookmarkEventGroupStanding = "bookmark_event_group_standing",
  BookmarkEventIsEditing = "bookmark_event_is_editing"
}
