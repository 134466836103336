import { RouterState } from "@/interfaces/store/router";
import { Route } from "@/router/routes";
import { Actions, Mutations } from "./operations";
import { GetterTree, MutationTree, ActionTree } from "vuex";

const state: RouterState = {
  active: undefined
};

const getters: GetterTree<RouterState, any> = {};

const actions: ActionTree<RouterState, any> = {
  [Actions.RouteChange]: (context, route: Route) => {
    context.commit(Mutations.RouteActiveSet, route);
  }
};

const mutations: MutationTree<RouterState> = {
  [Mutations.RouteActiveSet]: (state, route: Route) => {
    state.active = route;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
