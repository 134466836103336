import { UserLoginRequest, UserAuthResponse } from "@/interfaces/services/user";
import { apiService } from "./api";
import { authService } from "./auth";
import { interfaceService } from "./interface";
import { localStorageService } from "./local-storage";
import { UserDataService } from "./user-data";

class UserService extends UserDataService {
  protected dataKeys = ["name", "email", "rights"];
  protected reauthInterceptorId?: number;
  protected isReauthing = false;

  constructor() {
    super();
    this.reauthInterceptorId = apiService.addAuthRequestInterceptor(
      this.reauthInterceptor.bind(this)
    );
  }

  get personalName() {
    return localStorageService.get(localStorageService.keyUserDataName);
  }

  get personalEmail() {
    return localStorageService.get(localStorageService.keyUserDataEmail);
  }

  get personalRights(): string[] {
    const rights = localStorageService.getData(
      localStorageService.keyUserDataRights
    );
    return rights ? rights : [];
  }

  setAuthData(uar: UserAuthResponse) {
    authService.setToken(uar.token, uar.tokenExp);
    this.setData(uar);
    interfaceService.setData(uar);
  }

  removeAuthData() {
    authService.removeToken();
    this.removeData();
    interfaceService.removeData();
  }

  protected reauthInterceptor() {
    if (
      !this.isReauthing &&
      !authService.isTokenExpired() &&
      authService.isTokenExpiring()
    ) {
      this.isReauthing = true;
      this.reauth()
        .then(uar => this.setAuthData(uar))
        .finally(() => (this.isReauthing = false));
    }
  }

  protected getEndpoint(...parts: string[]) {
    return "users/" + (parts.length ? parts.join("/") : "");
  }

  async login(data: UserLoginRequest) {
    return (
      await apiService.post<UserAuthResponse>(this.getEndpoint("login"), {
        auth: false,
        data
      })
    ).data;
  }

  async reauth(): Promise<UserAuthResponse> {
    return (await apiService.put<UserAuthResponse>(this.getEndpoint("reauth")))
      .data;
  }
}

export const userService = new UserService();
