import { UserData } from "@/interfaces/services/user";
import { localStorageService } from "./local-storage";

export abstract class UserDataService {
  protected abstract dataKeys: string[];

  protected prepareDataKey(key: string) {
    return `ud_${key}`;
  }

  setData(data: UserData) {
    this.dataKeys.forEach(key => {
      let keyData = (data as any)[key];
      if (keyData !== undefined && keyData !== null && keyData !== "") {
        if (typeof keyData !== "string") {
          keyData = JSON.stringify(keyData);
        }
        localStorageService.set(this.prepareDataKey(key), keyData);
      }
    });
  }

  removeData() {
    this.dataKeys.forEach(key =>
      localStorageService.remove(this.prepareDataKey(key))
    );
  }
}
