import { ActionTree, GetterTree, MutationTree } from "vuex";
import i18n from "@/common/plugins/i18n";
import vuetify from "@/common/plugins/vuetify";
import { InterfaceState } from "@/interfaces/store/interface";
import { interfaceService } from "@/services/interface";
import { Actions, Mutations } from "./operations";

const state: InterfaceState = {
  drawerOpened: interfaceService.getDrawerOpened(),
  drawerMini: interfaceService.getDrawerMini(),
  language: interfaceService.getLanguage()
};

const getters: GetterTree<InterfaceState, any> = {};

const actions: ActionTree<InterfaceState, any> = {
  [Actions.DrawerToggle]: context => {
    context.commit(Mutations.DrawerOpenedToggle);
  },
  [Actions.DrawerMiniToggle]: context => {
    context.commit(Mutations.DrawerMiniToggle);
  },
  [Actions.LanguageChange]: (context, value: string) => {
    context.commit(Mutations.LanguageChange, value);
  }
};

const mutations: MutationTree<InterfaceState> = {
  [Mutations.DrawerOpenedToggle]: state => {
    state.drawerOpened = !state.drawerOpened;
    interfaceService.setDrawerOpened(state.drawerOpened);
  },
  [Mutations.DrawerMiniToggle]: state => {
    state.drawerMini = !state.drawerMini;
    interfaceService.setDrawerMini(state.drawerMini);
  },
  [Mutations.LanguageChange]: (state, value: string) => {
    state.language = value;
    interfaceService.setLanguage(state.language);
    i18n.locale = state.language;
    vuetify.framework.lang.current = state.language;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
