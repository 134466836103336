
import { Component, Vue } from "vue-property-decorator";
import { apiService } from "./services/api";
import { authService } from "./services/auth";
import LoginDialog from "@/components/views/LoginDialog.vue";

@Component({
  components: {
    LoginDialog
  }
})
export default class App extends Vue {
  authInterceptorId?: number;
  loginDialog = false;

  get layout() {
    return `layout-${this.$route.meta.layout || "default"}`;
  }

  get isThemeDark() {
    return this.theme === "dark";
  }

  get theme() {
    return this.$route.meta.theme || "light";
  }

  get isLoggedIn() {
    return authService.hasToken;
  }

  created() {
    this.authInterceptorId = apiService.addAuthRequestInterceptor(
      this.authInterceptor.bind(this)
    );
  }

  authInterceptor() {
    if (authService.isTokenExpired() && !this.loginDialog) {
      this.loginDialog = true;
      return false;
    }
  }
}
