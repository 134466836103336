import { RouteConfig } from "vue-router";

export enum Route {
  Login = "login",

  Endpoints = "endpoints",
  Events = "events",
  Event = "event",
  Errors = "errors"
}

export const prefixRoutes = (prefix: string, routes: RouteConfig[]) =>
  routes.map(rt => {
    rt.path = prefix + rt.path;
    if (rt.alias) {
      rt.alias = prefix + rt.alias;
    }
    return rt;
  });
