import { UserAuthResponse, UserLoginRequest } from "@/interfaces/services/user";
import { UserState } from "@/interfaces/store/user";
import { userService } from "@/services/user";
import { Actions, Getters, Mutations } from "./operations";
import { GetterTree, MutationTree, ActionTree } from "vuex";

const state: UserState = {
  loginLoading: false,
  loginErrors: []
};

const getters: GetterTree<UserState, any> = {
  [Getters.UserLoginHasErrors]: state => state.loginErrors.length > 0
};

const actions: ActionTree<UserState, any> = {
  [Actions.UserLogin]: async (context, data: UserLoginRequest) => {
    context.commit(Mutations.UserLoginLoadingToggle);
    context.commit(Mutations.UserAuthPurgeErrors);
    try {
      context.commit(Mutations.UserAuthSet, await userService.login(data));
    } catch (e) {
      context.commit(Mutations.UserAuthSetError, e.code || e.message);
      throw e;
    } finally {
      context.commit(Mutations.UserLoginLoadingToggle);
    }
  },
  [Actions.UserLogout]: context => context.commit(Mutations.UserAuthPurge)
};

const mutations: MutationTree<UserState> = {
  [Mutations.UserAuthSet]: (_, authRes: UserAuthResponse) => {
    userService.setAuthData(authRes);
  },
  [Mutations.UserAuthPurge]: () => {
    userService.removeAuthData();
  },
  [Mutations.UserAuthSetError]: (state, e: string) => {
    state.loginErrors.push(e);
  },
  [Mutations.UserAuthPurgeErrors]: state => {
    state.loginErrors = [];
  },
  [Mutations.UserLoginLoadingToggle]: state => {
    state.loginLoading = !state.loginLoading;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
