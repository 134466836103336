import Vue from 'vue';
import Vuetify from 'vuetify';
import sl from 'vuetify/src/locale/sl';
import { interfaceService } from '@/services/interface';
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { sl },
    current: interfaceService.getLanguage(),
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#005196',
        secondary: '#b53f3f',
        accent: '#6f608b',
        error: '#f44336',
        warning: '#ff9800',
        info: '#03a9f4',
        success: '#4caf50',
        background: colors.grey.lighten4,
      },
    },
  },
});
