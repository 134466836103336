import Vue from "vue";
import Component from "vue-class-component";

import LayoutBarebone from "@/layouts/Barebone.vue";
import LayoutDefault from "@/layouts/Default.vue";
import LayoutEmpty from "@/layouts/Empty.vue";

const registerHooks = () => {
  Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate"
  ]);
};

const registerComponents = () => {
  Vue.component("layout-barebone", LayoutBarebone);
  Vue.component("layout-default", LayoutDefault);
  Vue.component("layout-empty", LayoutEmpty);
};

const initConfig = () => {
  Vue.config.productionTip = false;
};

export default () => {
  registerHooks();
  registerComponents();
  initConfig();
};
