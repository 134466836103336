export enum Environment {
  Test = "test",
  Production = "prod"
}

export function getEnvironment(): string {
  return process.env.VUE_APP_ENV || "prod";
}

export function isEnvironment(env: Environment) {
  return getEnvironment() === env;
}

export function isEnvironmentTest() {
  return isEnvironment(Environment.Test);
}

export function isEnvironmentProduction() {
  return isEnvironment(Environment.Production);
}
