import { ListItems } from "@/interfaces/services/generic";
import { apiService } from "./api";

class EndpointService {
  protected getEndpoint(...parts: string[]) {
    return "endpoints/" + (parts.length ? parts.join("/") : "");
  }

  async list(perPage: number, page: number, search?: string) {
    return (
      await apiService.get<ListItems<string>>(this.getEndpoint(), {
        params: { perPage, page, s: search }
      })
    ).data;
  }
}

export const endpointService = new EndpointService();
