import { LocaleCodes } from "@/interfaces/locale/codes";
import {
  ApiError,
  ApiException,
  ApiHeaderOptions,
  ApiMethodBaseOptions,
  ApiMethodPostOptions,
  ApiMethodPutOptions,
  ApiResponse
} from "@/interfaces/services/api";
import axios from "axios";
import QueryString from "qs";
import { authService } from "./auth";

class ApiService {
  constructor(baseUrl: string) {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.paramsSerializer = params =>
      QueryString.stringify(params, { indices: false });
  }

  async get<T = any>(
    endpoint: string,
    options?: ApiMethodBaseOptions
  ): Promise<ApiResponse<T>> {
    try {
      const res = await axios.get(endpoint, {
        headers: this.getHeaders({
          ...options?.headers,
          auth: options?.auth !== undefined ? options.auth : true
        }),
        params: options?.params
      });
      return res;
    } catch (e) {
      throw this.transformError(e);
    }
  }

  async post<T = any>(
    endpoint: string,
    options?: ApiMethodPostOptions
  ): Promise<ApiResponse<T>> {
    try {
      const res = await axios.post(endpoint, options?.data, {
        headers: this.getHeaders({
          ...options?.headers,
          auth: options?.auth !== undefined ? options.auth : true
        }),
        params: options?.params,
        onUploadProgress: options?.onUploadProgress
      });
      return res;
    } catch (e) {
      throw this.transformError(e);
    }
  }

  async put<T = any>(
    endpoint: string,
    options?: ApiMethodPutOptions
  ): Promise<ApiResponse<T>> {
    try {
      const res = await axios.put(endpoint, options?.data, {
        headers: this.getHeaders({
          ...options?.headers,
          auth: options?.auth !== undefined ? options.auth : true
        }),
        params: options?.params
      });
      return res;
    } catch (e) {
      throw this.transformError(e);
    }
  }

  async patch<T = any>(
    endpoint: string,
    options?: ApiMethodPutOptions
  ): Promise<ApiResponse<T>> {
    try {
      const res = await axios.patch(endpoint, options?.data, {
        headers: this.getHeaders({
          ...options?.headers,
          auth: options?.auth !== undefined ? options.auth : true
        }),
        params: options?.params
      });
      return res;
    } catch (e) {
      throw this.transformError(e);
    }
  }

  async delete<T = any>(
    endpoint: string,
    options?: ApiMethodBaseOptions
  ): Promise<ApiResponse<T>> {
    try {
      const res = await axios.delete(endpoint, {
        headers: this.getHeaders({
          ...options?.headers,
          auth: options?.auth !== undefined ? options.auth : true
        }),
        params: options?.params
      });
      return res;
    } catch (e) {
      throw this.transformError(e);
    }
  }

  addAuthRequestInterceptor(func: () => void | boolean) {
    return axios.interceptors.request.use(
      cfg => {
        if (cfg.headers["Authorization"]) {
          const res = func();
          if (typeof res === "boolean") {
            throw new axios.Cancel(LocaleCodes.GeneralAuthTokenExpiredError);
          }
        }
        return cfg;
      },
      err => Promise.reject(err)
    );
  }

  removeAuthRequestInterceptor(id: number) {
    axios.interceptors.request.eject(id);
  }

  protected getHeaders(options: ApiHeaderOptions) {
    const headers: { [key: string]: string } = {};
    if (options.auth) {
      headers["Authorization"] = "Bearer " + authService.getToken();
    }
    return headers;
  }

  protected transformError(e: ApiException): ApiError {
    let code: LocaleCodes | string | undefined =
      LocaleCodes.GeneralUnknownError;
    if (e.response && e.response.data && e.response.data.message) {
      code = e.response.data.message;
    } else {
      const messageCodeMap: { [msg: string]: LocaleCodes | string } = {
        "Network Error": LocaleCodes.GeneralNetworkError
      };
      const messageCode = messageCodeMap[e.message];
      if (messageCode !== undefined) {
        code = messageCode;
      }
    }
    if (e.message === LocaleCodes.GeneralAuthTokenExpiredError) {
      // NOTE: Do not display general auth token expired error, because app handles this via login dialog
      code = undefined;
    }
    return new ApiError(code, e.message, e);
  }
}

export const apiService = new ApiService(process.env.VUE_APP_API_BASE_URL);
