import Vue from 'vue';
import Vuex from 'vuex';

import route from './route.module';
import iface from './interface.module';
import user from './user.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    route,
    iface,
    user,
  },
});
