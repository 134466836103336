
import { Route } from "@/router/routes";
import { Component, Vue } from "vue-property-decorator";
import { navigation } from "@/router/navigation";
import { Actions } from "@/store/operations";
import {
  NavigationComponent,
  NavigationComponentType
} from "@/interfaces/store/router";

interface NavigationItem {
  name: string;
  title: string;
  route?: string;
  icon?: string;
  items?: Navigation;
}
type Navigation = NavigationItem[];

@Component
export default class NavigationDrawer extends Vue {
  get opened() {
    return this.$store.state.iface.drawerOpened;
  }

  get mini() {
    return this.$store.state.iface.drawerMini;
  }

  get nav() {
    return navigation.getItems();
  }

  isParentActive(parentRoute: Route) {
    return (
      this.active &&
      (this.active === parentRoute || this.active.startsWith(`${parentRoute}_`))
    );
  }

  toggleMini() {
    this.$store.dispatch(Actions.DrawerMiniToggle);
  }

  isCompSection(item: NavigationComponent) {
    return item.getType() === NavigationComponentType.Section;
  }

  isCompItem(item: NavigationComponent) {
    return item.getType() === NavigationComponentType.Item;
  }

  protected get active(): string {
    return this.$store.state.route.active;
  }
}
