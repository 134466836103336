
import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { Actions, Getters } from "@/store/operations";
import { Route } from "@/router/routes";
import { LocaleMap } from "@/common/locale";
import { isEnvironmentTest } from "@/common/utils/env";

@Component({
  mixins: [validationMixin],
  validations: {
    username: { required },
    password: { required }
  }
})
export default class Login extends Vue {
  username = "";
  password = "";

  get errorsUsername() {
    const errors = [];
    if (this.$v.username.$dirty) {
      if (!this.$v.username.required) {
        errors.push(this.$t("LOGIN_FORM_ERROR_USERNAME_REQUIRED"));
      }
    }
    return errors;
  }

  get errorsPassword() {
    const errors = [];
    if (this.$v.password.$dirty) {
      if (!this.$v.password.required) {
        errors.push(this.$t("LOGIN_FORM_ERROR_PASSWORD_REQUIRED"));
      }
    }
    return errors;
  }

  get errorsGeneral() {
    return this.$store.state.user.loginErrors;
  }

  get hasErrors() {
    return this.$store.getters[Getters.UserLoginHasErrors];
  }

  get isLoading() {
    return this.$store.state.user.loginLoading;
  }

  get languages() {
    return Object.keys(LocaleMap).map(localeKey => ({
      text: LocaleMap[localeKey],
      value: localeKey
    }));
  }

  get language() {
    return this.$store.state.iface.language;
  }

  set language(language: string) {
    this.$store.dispatch(Actions.LanguageChange, language);
  }

  get isTestEnv() {
    return isEnvironmentTest();
  }

  submit() {
    if (!this.$v.$invalid) {
      this.$store
        .dispatch(Actions.UserLogin, {
          username: this.username,
          password: this.password
        })
        .then(() => this.$router.push({ name: Route.Endpoints }));
    }
  }
}
