import { localStorageService } from "./local-storage";

class AuthService {
  isAuthenticated() {
    return this.token !== null && !this.isTokenExpired();
  }

  isTokenExpired() {
    return this.tokenExpiration
      ? this.tokenExpiration < this.getDateSeconds()
      : true;
  }

  isTokenExpiring() {
    return this.tokenExpiration
      ? this.tokenExpiration < this.getDateSeconds() + 90 * 60
      : true;
  }

  getToken() {
    return this.token;
  }

  setToken(token: string, tokenExpiration: string) {
    localStorageService.set(localStorageService.keyAuthToken, token);
    localStorageService.set(
      localStorageService.keyAuthTokenExpiration,
      this.getDateSeconds(new Date(tokenExpiration)).toString()
    );
  }

  removeToken() {
    localStorageService.remove(localStorageService.keyAuthToken);
  }

  get hasToken() {
    return this.token !== null;
  }

  protected get token() {
    return localStorageService.get(localStorageService.keyAuthToken);
  }

  protected get tokenExpiration() {
    const exp = localStorageService.get(
      localStorageService.keyAuthTokenExpiration
    );
    return exp ? parseInt(exp) : null;
  }

  protected getDateSeconds(date?: Date) {
    return (date ? date.getTime() : Date.now()) / 1000;
  }
}

export const authService = new AuthService();
