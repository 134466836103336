import Vue from "vue";
import VueI18n from "vue-i18n";
import { interfaceService } from "@/services/interface";

import en from "@/common/locale/en.json";
import sl from "@/common/locale/sl.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: interfaceService.getLanguage(),
  messages: { en, sl }
});
