
import { Component, Vue } from "vue-property-decorator";
import Barebone from "@/layouts/Barebone.vue";
import TitleBasic from "@/components/layout/TitleBasic.vue";

@Component({
  components: {
    Barebone,
    TitleBasic
  }
})
export default class Default extends Vue {}
