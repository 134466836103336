import { Layout } from "@/layouts";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Endpoints from "../views/Endpoints.vue";
import Login from "../views/Login.vue";
import { authService } from "@/services/auth";
import { prefixRoutes, Route } from "./routes";
import store from "@/store";
import { Actions } from "@/store/operations";
import { LocaleCodes } from "@/interfaces/locale/codes";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: Route.Endpoints,
    component: Endpoints,
    meta: {
      layout: Layout.Barebone,
      title: LocaleCodes.PageTitleEndpoints
    }
  },
  {
    path: "/login",
    name: Route.Login,
    component: Login,
    meta: {
      layout: Layout.Empty,
      noAuth: true
    }
  },
  ...prefixRoutes("/events", [
    {
      path: "/",
      name: Route.Events,
      component: () => import("../views/events/Main.vue"),
      meta: {
        layout: Layout.Barebone,
        title: LocaleCodes.PageTitleEvents
      }
    },
    {
      path: "/:id",
      name: Route.Event,
      component: () => import("../views/events/Details.vue"),
      meta: {
        layout: Layout.Barebone
      }
    }
  ]),
  ...prefixRoutes("/errors", [
    {
      path: "/",
      name: Route.Errors,
      component: () => import("../views/errors/Main.vue"),
      meta: {
        layout: Layout.Barebone,
        title: LocaleCodes.PageTitleErrors
      }
    }
  ])
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _, next) => {
  const isAuthenticated = authService.isAuthenticated();
  if ((!to.meta || !to.meta.noAuth) && !isAuthenticated) {
    return next({ name: Route.Login });
  } else if (!to.name || (to.name === Route.Login && isAuthenticated)) {
    return next({ name: Route.Endpoints });
  }

  next();
});

router.beforeResolve(async (to, _, next) => {
  await store.dispatch(Actions.RouteChange, to.name);
  next();
});

export default router;
