class LocalStorageService {
  readonly keyAuthToken = "at";
  readonly keyAuthTokenExpiration = "at_exp";
  readonly keyUserDataName = "ud_name";
  readonly keyUserDataEmail = "ud_email";
  readonly keyUserDataRights = "ud_rights";
  readonly keyUserDataLocalSettings = "ud_localSettings";
  readonly keyUserDataInterfaceSettings = "ud_interfaceSettings";

  get(key: string) {
    return window.localStorage.getItem(key);
  }

  getData(key: string) {
    const data = this.get(key);
    return data ? JSON.parse(data) : data;
  }

  set(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  remove(key: string) {
    window.localStorage.removeItem(key);
  }
}

export const localStorageService = new LocalStorageService();
