import { Route } from "@/router/routes";
import { LocaleCodes } from "../locale/codes";

export interface RouterState {
  active?: Route;
}

export interface NavigationBreadcrumbs {
  text: string;
  disabled?: boolean;
  to: string;
  link: boolean;
  exact: boolean;
}

export enum NavigationComponentType {
  Section,
  Item
}

export abstract class NavigationComponent {
  abstract getType(): NavigationComponentType;
}

export class NavigationSection extends NavigationComponent {
  protected title: string;
  protected items: NavigationItem[] = [];

  constructor(title: string) {
    super();
    this.title = title;
  }

  addItem(item: NavigationItem) {
    this.items.push(item);

    return this;
  }

  getItems() {
    return this.items;
  }

  getTitle() {
    return this.title;
  }

  getType() {
    return NavigationComponentType.Section;
  }
}

export class NavigationItem extends NavigationComponent {
  protected name: Route;
  protected title: string;
  protected route?: string;
  protected icon?: string;
  protected children: NavigationItem[];
  protected parent?: NavigationItem;
  protected map: { [route: string]: NavigationItem };

  constructor(name: Route, title: string, route?: string, icon?: string) {
    super();
    this.name = name;
    this.title = title;
    this.route = route;
    this.icon = icon;
    this.children = [];
    this.map = {
      [name]: this
    };
  }

  getName() {
    return this.name;
  }

  getTitle() {
    return this.title;
  }

  getRoute() {
    return this.route;
  }

  getIcon() {
    return this.icon;
  }

  addChild(child: NavigationItem) {
    this.children.push(child.setParent(this));
    this.map = {
      ...this.map,
      [child.getName()]: child,
      ...child.getMap()
    };
    return this;
  }

  hasChildren() {
    return this.children.length > 0;
  }

  getChildren() {
    return this.children;
  }

  getParent() {
    return this.parent;
  }

  setParent(parent: NavigationItem) {
    this.parent = parent;
    return this;
  }

  getRouteTo() {
    if (this.route === undefined) {
      return null;
    }

    let route = this.route;
    let parent = this.parent;
    while (parent !== undefined) {
      if (parent.route !== undefined) {
        route = parent.route + route;
        parent = parent.parent;
      } else {
        parent = undefined;
      }
    }
    const rtLastCharIdx = route.length - 1;
    return rtLastCharIdx > 0 && route[rtLastCharIdx] === "/"
      ? route.substr(0, rtLastCharIdx)
      : route;
  }

  getMap() {
    return this.map;
  }

  getType() {
    return NavigationComponentType.Item;
  }
}

export class Navigation {
  protected items: NavigationComponent[] = [];
  protected map: { [route: string]: NavigationItem } = {};

  addSection(section: NavigationSection) {
    this.items.push(section);

    return this;
  }

  addItem(item: NavigationItem) {
    this.items.push(item);

    const itemMap = item.getMap();
    Object.keys(itemMap).forEach(
      itemKey => (this.map[itemKey] = itemMap[itemKey])
    );
    return this;
  }

  getItems() {
    return this.items;
  }

  getBreadcrumbs(route: Route) {
    const breadcrumbs: NavigationBreadcrumbs[] = [];
    if (route !== Route.Endpoints && this.map[route] !== undefined) {
      let navItem = this.map[route];
      while (navItem !== undefined) {
        const parentItem = navItem.getParent();
        const routeTo = navItem.getRouteTo();
        breadcrumbs.unshift({
          text: navItem.getTitle(),
          link: true,
          exact: true,
          disabled: !routeTo,
          to: routeTo || ""
        });
        navItem = parentItem as any;
      }
    }

    breadcrumbs.unshift({
      text: LocaleCodes.NavigationItemEndpoints,
      to: "/",
      link: true,
      exact: true
    });
    return breadcrumbs;
  }
}
