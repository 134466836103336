
import { isEnvironmentTest } from "@/common/utils/env";
import { Actions, Getters } from "@/store/operations";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    username: { required },
    password: { required }
  }
})
export default class LoginDialog extends Vue {
  @Prop()
  value!: boolean;

  username = "";
  password = "";

  get errorsUsername() {
    const errors = [];
    if (this.$v.username.$dirty) {
      if (!this.$v.username.required) {
        errors.push(this.$t("LOGIN_FORM_ERROR_USERNAME_REQUIRED"));
      }
    }
    return errors;
  }

  get errorsPassword() {
    const errors = [];
    if (this.$v.password.$dirty) {
      if (!this.$v.password.required) {
        errors.push(this.$t("LOGIN_FORM_ERROR_PASSWORD_REQUIRED"));
      }
    }
    return errors;
  }

  get errorsGeneral() {
    return this.$store.state.user.loginErrors;
  }

  get hasErrors() {
    return this.$store.getters[Getters.UserLoginHasErrors];
  }

  get isLoading() {
    return this.$store.state.user.loginLoading;
  }

  get isTestEnv() {
    return isEnvironmentTest();
  }

  @Emit()
  input(value: boolean) {
    return value;
  }

  @Watch("value")
  visibility() {
    if (this.value) {
      this.resetInput();
    }
  }

  onInput(value: boolean) {
    this.input(value);
  }

  resetInput() {
    this.username = "";
    this.password = "";
    this.$v.$reset();
  }

  submit() {
    if (!this.$v.$invalid) {
      this.$store
        .dispatch(Actions.UserLogin, {
          username: this.username,
          password: this.password
        })
        .then(() => this.onInput(false));
    }
  }
}
