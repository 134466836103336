
import { navigation } from "@/router/navigation";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavigationBreadcrumbs extends Vue {
  get items() {
    return navigation.getBreadcrumbs(this.routeActive).map(navBreadcrumb => ({
      ...navBreadcrumb,
      text: this.$t(navBreadcrumb.text)
    }));
  }

  get routeActive() {
    return this.$store.state.route.active;
  }
}
