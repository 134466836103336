
import { debounce } from "debounce";
import { Component, Vue } from "vue-property-decorator";
import { endpointService } from "@/services/endpoint";
import TitleBasic from "@/components/layout/TitleBasic.vue";

@Component({
  components: {
    TitleBasic
  }
})
export default class Endpoints extends Vue {
  loading = true;
  error: string | null = null;
  items: string[] = [];
  itemsCount = 0;
  itemsPage = 1;
  itemsPerPage = 20;

  search: string | null = null;
  searchResolve = debounce(this.updateSearch, 500);

  get itemsLength() {
    return Math.ceil(this.itemsCount / this.itemsPerPage);
  }

  get hasItems() {
    return this.itemsCount > 0;
  }

  created() {
    this.itemsLoadData();
  }

  itemsLoadData() {
    this.error = null;
    this.loading = true;
    endpointService
      .list(this.itemsPerPage, this.itemsPage, this.search || undefined)
      .then(data => {
        this.items = data.items || [];
        this.itemsCount = data.count;
      })
      .catch(e => (this.error = e.code))
      .finally(() => (this.loading = false));
  }

  updateSearch(value: string) {
    this.search = value;
    this.itemsLoadData();
  }
}
