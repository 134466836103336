
import { Component, Prop, Vue } from "vue-property-decorator";
import NavigationBreadcrumbs from "@/components/layout/NavigationBreadcrumbs.vue";

@Component({
  components: {
    NavigationBreadcrumbs
  }
})
export default class TitleVerticalBar extends Vue {
  @Prop()
  title!: string;
}
