
import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/layout/AppBar.vue";
import NavigationDrawer from "@/components/layout/NavigationDrawer.vue";
import NavigationBreadcrumbs from "@/components/layout/NavigationBreadcrumbs.vue";

@Component({
  components: {
    AppBar,
    NavigationDrawer,
    NavigationBreadcrumbs
  }
})
export default class Barebone extends Vue {}
